<template>
  <div id="user">
    <div role="alert" class="el-alert el-alert--success is-light" style="display: none;"><!---->
      <div class="el-alert__content">
        <span class="el-alert__title">版本公告</span><!----><!---->
        <i class="el-alert__closebtn el-icon-close"></i>
      </div>
    </div>
    <el-tabs type="border-card" v-model="tabPosition">
  <el-tab-pane label="開卡" >
        <div role="tabpanel" id="pane-first" aria-labelledby="tab-first" class="el-tab-pane">
          <form class="el-form demo-ruleForm">
            <div class="el-form-item"><label for="card" class="el-form-item__label" style="width: 100px;">開卡</label>
              <div class="el-form-item__content" style="margin-left: 100px;">
                <div class="el-input el-input-group el-input-group--append"><!---->
                  <input type="text" autocomplete="" class="el-input__inner" placeholder="請輸入卡號" v-model="card.card_serial">

                  <!----><!---->

                </div><!---->
                <div class="el-input el-input-group el-input-group--append"><!---->
                  <el-input v-model="card.card_secret" placeholder="請輸入密碼"></el-input>
                </div>
                <div class="el-input el-input-group el-input-group--append"><!---->
                  <el-input v-model="card.machine_id" placeholder="請輸入硬體識別碼"></el-input>
                  <div class="el-input-group__append">
                    <button type="button" @click="submitOpenCard" class="el-button el-button--default"><span>啟動卡號</span></button>
                  </div><!---->
                </div>

              </div>
            </div>
          </form>
        </div>
    <p>{{msg.info}}</p>
    <br>
    <img :src="openImage" alt="開卡教學">
    <img :src="changeidImage" alt="轉綁教學">

  </el-tab-pane>

  <el-tab-pane label="卡號轉綁" :disabled="disabled.control"><div role="tabpanel" id="pane-second" aria-labelledby="tab-second" class="el-tab-pane">
          <form class="el-form demo-ruleForm">
            <div class="el-form-item"><label for="card" class="el-form-item__label" style="width: 100px;">卡號轉綁</label>
              <div class="el-form-item__content" style="margin-left: 100px;">
                <div class="el-input el-input-group el-input-group--append"><!---->
                  <input type="text" autocomplete="" class="el-input__inner" placeholder="請輸入卡號" v-model="card.card_serial">

                  <!----><!---->

                </div><!---->
                <div class="el-input el-input-group el-input-group--append"><!---->
                  <el-input v-model="card.card_secret" placeholder="請輸入密碼"></el-input>
                </div>
                <div class="el-input el-input-group el-input-group--append"><!---->
                  <el-input v-model="card.machine_id" placeholder="請輸入硬體識別碼"></el-input>
                  <div class="el-input-group__append">
                    <button type="button" @click="submitMoveCard" class="el-button el-button--default"><span>確認轉綁</span></button>
                  </div><!---->
                </div>

              </div>
            </div>
          </form>
        </div>
    {{msg.info2}}</el-tab-pane>


  <el-tab-pane label="外掛下載" >
    <div role="tabpanel" id="pane-third" aria-labelledby="tab-third" class="el-tab-pane">
      <h2 class="text-muted" style="color: rgb(23, 35, 61); font-size: 25px; display: flex; justify-content: center;">外掛下載</h2>

      網站更新時間：2022-10-13
      <p style="font-size: 18px; color: rgb(255, 0, 0);">APEX及逃離塔科夫用戶，請私訊官方LINE:@avenger.store 獲取2022最新程式 </p>


    </div>
  </el-tab-pane>


  <el-tab-pane label="使用教學" >
    <div role="tabpanel" id="pane-fourth" aria-labelledby="tab-fourth" class="el-tab-pane">
      <h2 class="text-muted" style="color: rgb(23, 35, 61); font-size: 25px; display: flex; justify-content: center;">
          使用教學</h2>
          <h4 style="color: rgb(64, 158, 255);">開啟步驟：</h4>
          <p style="font-size: 18px; color: rgb(64, 158, 255);">1:下載外掛前請先關閉防毒軟體</p>
          <p style="font-size: 18px; color: rgb(64, 158, 255);">2:載好程式，右鍵解壓縮</p>
          <p style="font-size: 18px; color: rgb(64, 158, 255);">3:使用右鍵管理者模式開啟外掛，並且複製外掛上的硬體識別碼</p>
          <p style="font-size: 18px; color: rgb(64, 158, 255);">4:於開卡網頁上輸入：卡號/密碼/硬體識別碼，進行開卡</p>
          <h4 style="color: rgb(237, 64, 20);">注意事项：</h4>
          <p style="font-size: 30px; color: rgb(255, 0, 0);">如需遠端協助，麻煩請先去載Anydesk後，聯繫客服人員</p>
          <p style="font-size: 18px; color: rgb(255, 153, 0);">1:先右鍵管理者模式啟動外掛後，再啟動遊戲！！！</p>
          <p style="font-size: 18px; color: rgb(255, 153, 0);">2:外掛於卡號到期之前一直有效，外掛啟動後到期時間會於外掛內出現！！</p>
          <p style="font-size: 18px; color: rgb(255, 153, 0);">3:請勿重複開啟外掛！！！！</p>
          <p style="font-size: 18px; color: rgb(255, 153, 0);">4:如遇版本更新，且遊戲外掛處於失效狀態，麻煩直接聯繫客服人員處理！！！</p>
          </div>
    <div role="tabpanel" aria-hidden="true" id="pane-helpvideo" aria-labelledby="tab-helpvideo" class="el-tab-pane">
<p style="font-size: 18px; color: rgb(255, 153, 0);">影片教學製作中...<a href="#" arget="_blank">教學載點製作中...</a>
      
        </p>
          <video src="https://abc.com/abc.mp4" controls="controls" width="100%" class="video"></video>
        </div>
    {{tabPosition}}</el-tab-pane>
</el-tabs>
  </div>

</template>

<script>
import {ref, reactive, onMounted} from 'vue'
import axios from "axios";
import openImg from '@/assets/open.png';
import changeidImg from '@/assets/changeid.png';
const api_url = 'https://avenger.store/api/card/'
export default {
  setup() {
    const openImage= reactive(openImg);
    const changeidImage= reactive(changeidImg);
    const tabPosition= reactive(0);
    const down = reactive({
      content:'點我下載',
      totalTime:60,
      canClick:true
    });
    const msg = reactive({
      info:'',
      info2:''
    });
    const disabled = reactive({
      control: true
    });

    const card = reactive({
      card_serial:'',
      card_secret:'',
      machine_id:''
    });
    const cdown = () => {
      if (!down.canClick){
        return;
      }
      down.canClick = false;
      down.content = down.totalTime + 's 提取檔案中請勿刷新...';
      window.open('https://api-av.raider.nz/api/file/release?api_token=bOO6Nj47s7WU6nz7gakxlI9O2fav0Q6XgT7y4wNn2FmIFhfyzPpczHrKZXbGyKp42eGM2T7f7xYBHjP6','_blank');
      let clock = window.setInterval(() => {
        down.totalTime--;
        down.content = down.totalTime + 's 提取檔案中請勿刷新...';
        if (down.totalTime < 0) {
          window.clearInterval(clock);
          down.content = '點我下載';
          down.totalTime = 60;
          down.canClick = true;
        }
      },1000);
    }
    const submitOpenCard = () => {
      if(card.card_serial!='' && card.card_secret!='' && card.machine_id!=''){
        axios.put(api_url+card.card_serial+'/',card
            ).then(response => {
              if(response.data.status=='AlreadyOpen'){
                //var NewArray = response.data.expire.split("T")
                //var time = NewArray[1].split(".")
                //msg.info = '該卡片已開卡過，到期日為：'+NewArray[0]+' '+time[0]
                msg.info = '該卡片已開卡過，到期日為：'+response.data.expire
                disabled.control = false
              }
              if(response.data.status=='NotFound'){
                msg.info = '卡號或密碼錯誤'
              }
	      if(response.data.status=='NotYetExpire'){
		var NewArray = response.data.expire.split("T")
                var time = NewArray[1].split(".")
		msg.info = '硬體識別碼綁定之卡號尚未到期，到期日為：'+NewArray[0]+' '+time[0]
	      }
              if(response.data.status=='success'){

                var NewArray = response.data.expire.split("T")
                var time = NewArray[1].split(".")
                var thours = time[0].split(":")

                msg.info = '開卡成功，到期日為：'+NewArray[0]+' '+thours[0]+':'+thours[1]+':'+thours[2]
              }
              //console.log(response.data.status)
              //router.push("/tasklist");

            }).catch(e => {
            console.log(e)
            });
      }else{
        console.log('no data');
      }

    }

    const submitMoveCard = () => {
      if(card.card_serial!='' && card.card_secret!='' && card.machine_id!=''){
        axios.post(api_url+'changeid/',card
            ).then(response => {
              if(response.data.status=='success'){
                //var NewArray = response.data.expire.split("T")
                //var time = NewArray[1].split(".")
                //msg.info2 = '轉綁成功，扣除3小時使用時間，到期日為：'+NewArray[0]+' '+time[0]
                msg.info2 = '轉綁成功，扣除3小時使用時間，到期日為：' + response.data.expire
                disabled.control = false
              }else{
                msg.info2 = response.data.status
              }

              //console.log(response.data.status)
              //router.push("/tasklist");

            }).catch(e => {
            console.log(e)
            });
      }else{
        console.log('no data');
      }

    }
    return {
      tabPosition,
      msg,
      disabled,
      card,
      down,
      submitOpenCard,
      submitMoveCard,
      cdown,
      openImage,
      changeidImage

    }
  }

};
</script>
